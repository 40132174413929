body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content{
  min-height: calc(100vh - 128px);
}

.header-img{
  position: absolute;
  top: 7px;
  right: 0;
  height: 50px;
}
.movies{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.radio{
  text-align: left;

}
.radio span{
  margin-right: 2rem;
}
.more{
  position:absolute;
  bottom: -10px;
  right: 10px;
}
.count{
  font-weight: bold;
  font-size: 2rem;
  float: right;
  margin-right: 10px;
}
.marginRight{
  margin-right: 2rem;
}
.green{
  padding-top: 0;
}

.top{
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 8%;
  right: 5%;
  cursor: pointer;
  z-index: 2;
}
.top img{
  width: 100%;
}
.single-card{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 1rem 0;
  padding: 2rem 0;
}
.single-card-content{
  font-weight: bold;
  font-size: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card {
  min-height: 97%;
}
.card-image {
  text-align: center;
}
@media (max-width: 576px  ){
  .radio{
    display:flex;
    flex-direction: column;
  }
  .more{
    bottom: 33%;
  }
}